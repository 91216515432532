import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RewardService } from '../service/reward.service';
import { rewardSystemActions } from './reward-system.actions';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class RewardSystemEffects {
  public readonly loadOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rewardSystemActions.loadCoinOverview),
      switchMap(() =>
        this.rewardService.getOverview().pipe(
          map(overview => rewardSystemActions.loadCoinOverviewSuccess({ overview })),
          catchError(error => of(rewardSystemActions.loadCoinOverviewFailure({ error })))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly rewardService: RewardService
  ) {}
}
