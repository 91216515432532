import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoinCapDay, CoinEarning, CoinOverview } from '../model/coins';
import { RewardItemOrder, RewardItemOrderRequest, RewardItemOrderResponse } from '../model/rewards';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@fitup-monorepo/core/lib/services/api/api.service';

@Injectable({ providedIn: 'root' })
export class RewardService {
  constructor(private readonly httpClient: HttpClient) {}

  public getOverview(): Observable<CoinOverview> {
    return this.httpClient.get<CoinOverview>(`${ApiService.API_URL}/reward`);
  }

  public getCoinCapDay(day: string): Observable<CoinCapDay> {
    return this.httpClient.get<CoinCapDay>(`${ApiService.API_URL}/reward/coin-cap?day=${day}`);
  }

  public getCoinEarnings(from: Date, to: Date, pageSize: number, pageNum: number): Observable<CoinEarning[]> {
    return this.httpClient.get<CoinEarning[]>(`${ApiService.API_URL}/reward/coin`, {
      params: {
        from: from.toISOString(),
        to: to.toISOString(),
        pageSize,
        pageNum
      }
    });
  }

  public orderReward(order: RewardItemOrderRequest): Observable<RewardItemOrderResponse> {
    return this.httpClient.post<RewardItemOrderResponse>(`${ApiService.API_URL}/reward/order`, order);
  }

  public listOrders(): Observable<RewardItemOrder[]> {
    return this.httpClient.get<RewardItemOrder[]>(`${ApiService.API_URL}/reward/order`);
  }
}
