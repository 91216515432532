import { TranslatableString } from '@fitup-monorepo/core/lib/services/translatable-text/translatable-string';
import { Challenge, JoinedUsers } from './challenge';
import { ChallengeGoal, ChallengeGoalType } from './challenge-goal';

/**
 * A league is a group of users, that can compete against each other in challenges.
 */
export interface Group {
  id: number;
  approved: boolean;
  name: TranslatableString;
  description: TranslatableString;
  logoUrl: string;
  hasJoined: boolean;
  canJoin: boolean;
  canLeave: boolean;
  canEdit: boolean;
  canInvite: boolean;
  invitationCode?: string;
  activeChallenge?: Challenge;
  joinedUsers?: JoinedUsers;
  repetitionMode: RepetitionMode;
  challengeGoalType: ChallengeGoalType;
  goal: ChallengeGoal;
}

export interface GroupOverview {
  joinedLeagues: Group[];
  availableLeagues: Group[];
}

export interface GroupCreateOrUpdateRequest {
  name: TranslatableString;
  description: TranslatableString;
  logoBase64: string;
  repetitionMode: RepetitionMode;
  goal: ChallengeGoal;
  challengeGoalType: ChallengeGoalType;
}

export enum RepetitionMode {
  monthly = 'monthly',
  quarterly = 'quarterly'
}

export enum GroupType {
  public = 'public',
  corporate = 'corporate'
}
