import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoginService } from '@fitup-monorepo/core/lib/services/login/login.service';
import { IonicModule, NavController } from '@ionic/angular';
import { LocalStorageService } from '@fitup-monorepo/core/lib/services/local-storage.service';
import { ToastService } from '@fitup-monorepo/core/lib/services/toast/toast.service';
import { OAuth2Service } from '@fitup-monorepo/core/lib/services/auth/oauth2.service';
import { EditorComponent } from '@fitup-monorepo/components/lib/editor/editor.component';
import { InputElementComponent } from '@fitup-monorepo/components/lib/input-element/input-element.component';
import { RouterModule } from '@angular/router';
import { AppCheckerService } from '@fitup-monorepo/core/lib/util/app-checker.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [EditorComponent, InputElementComponent, IonicModule, ReactiveFormsModule, TranslateModule, RouterModule],
  standalone: true
})
export class SignInComponent implements OnInit {
  @Input()
  public entranceRoute: string;

  @Output()
  public closeModalAction = new EventEmitter<void>();

  public readonly form: FormGroup = this.formBuilder.group(
    {
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    },
    { updateOn: 'blur' }
  );

  #loginErrorString: string | undefined;
  protected readonly previousUrl = '/register';
  protected readonly isButtonDisabled = signal(false);
  protected readonly showOAuthLogin = this.appCheckerService.showOAuthLogin();

  constructor(
    private readonly translateService: TranslateService,
    private readonly loginService: LoginService,
    private readonly navController: NavController,
    private readonly localStorage: LocalStorageService,
    private readonly formBuilder: FormBuilder,
    private readonly toastService: ToastService,
    private readonly oauth2: OAuth2Service,
    private readonly appCheckerService: AppCheckerService
  ) {}

  public ngOnInit(): void {
    const username = this.localStorage.retrieve('auth_mail');
    if (username) {
      this.form.patchValue({ username });
    }
    this.translateService.get('LOGIN_ERROR').subscribe(value => {
      this.#loginErrorString = value;
    });
  }

  protected registerWithOauth2(provider: 'google' | 'apple'): void {
    const entranceRoute = this.entranceRoute ? `/tabs/notifications/pinboard-detail/${this.entranceRoute}` : '/tabs';
    this.oauth2.startOAuth2(provider, entranceRoute);
  }

  protected async doLogin(): Promise<void> {
    this.isButtonDisabled.set(true);

    try {
      await this.loginService.login({ ...this.form.value, rememberMe: true });
      await this.handleLoginSuccess();
    } catch (e) {
      console.log('Login failed', e);
      await this.showLoginErrorToast();
    }
  }

  private async handleLoginSuccess(): Promise<void> {
    this.isButtonDisabled.set(false);
    const url = this.entranceRoute ? `/tabs/notifications/pinboard-detail/${this.entranceRoute}` : '/tabs';
    await this.navController.navigateRoot(url);
    this.closeModalAction.emit();
  }

  private async showLoginErrorToast(): Promise<void> {
    this.isButtonDisabled.set(false);
    this.form.patchValue({ password: '' });
    await this.toastService.showError(this.#loginErrorString);
  }
}
