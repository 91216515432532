import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { formatNumber } from '@angular/common';
import { ChallengeGoal } from '../model';
import { getNumberFormatForChallengeGoal } from './challenge.util';

@Pipe({
  name: 'challengeValue',
  pure: false,
  standalone: true,
})
export class ChallengeValuePipe implements PipeTransform, OnDestroy {
  private value: string | undefined;
  private langChangeSubscription: Subscription | undefined;

  constructor(private readonly translateService: TranslateService) {}

  public transform(value: number, challengeGoal: ChallengeGoal): string {
    this.setCurrentValue(value, challengeGoal);
    if (!this.langChangeSubscription) {
      this.langChangeSubscription =
        this.translateService.onLangChange.subscribe(() => {
          this.setCurrentValue(value, challengeGoal);
        });
    }
    return this.value;
  }

  private setCurrentValue(value: number, challengeGoal: ChallengeGoal): void {
    this.value = `${formatNumber(
      value,
      this.translateService.currentLang ?? 'en',
      getNumberFormatForChallengeGoal(challengeGoal),
    )} ${this.translateService.instant('UNIT_ABBREV.' + challengeGoal)}`;
  }

  public ngOnDestroy(): void {
    this.langChangeSubscription?.unsubscribe();
  }
}
