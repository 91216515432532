import {
  Challenge,
  ChallengeGoal,
  ChallengeGoalIntervalType,
  ChallengeGoalType,
  ChallengeType,
  TeamMetrics
} from '../model';
import { CompletedChallenge } from '../components/completed-challenge-modal/completed-challenge-modal.component';
import { TeamCustomer } from '@fitup-monorepo/core/lib/model/team-customer';
import { getMinutesLeftUntil } from '@fitup-monorepo/core/lib/util/date.util';

export const defaultTeamChallengeImage =
  'https://res.cloudinary.com/catapult/image/upload/v1685006896/fitup/images/00-Challenge-Default-Image_hxzdir.jpg';

export const defaultBadgeUrl = 'assets/img/universal-challenge-badge.png';

export function getCurrentTeam(teams: TeamMetrics[], teamCustomer: TeamCustomer): TeamMetrics {
  return teams.find(team => team.name === teamCustomer?.team?.title);
}

export function getDefaultImageByType(challengeGoalType: ChallengeGoalType): string {
  switch (challengeGoalType) {
    case ChallengeGoalType.running:
      return 'https://res.cloudinary.com/catapult/image/upload/v1685450336/fitup/images/CH-01-Running_tvasd2.jpg';
    case ChallengeGoalType.cycling:
      return 'https://res.cloudinary.com/catapult/image/upload/v1685450336/fitup/images/CH-02-Biking_jwpcx3.jpg';
    case ChallengeGoalType.walking: // would be steps, but we don't have this as a goal yet
      return 'https://res.cloudinary.com/catapult/image/upload/v1685450337/fitup/images/CH-03-Steps_y3ticj.jpg';
    default:
      return defaultTeamChallengeImage;
  }
}

export function getNumberOfCompletedIntervals(challenge: Challenge): number {
  return challenge.progress?.intervalScores?.filter(score => score >= challenge.goalValue).length ?? 0;
}

export function getTranslationForInterval(goalInterval: ChallengeGoalIntervalType, isPlural: boolean): string {
  switch (goalInterval) {
    case ChallengeGoalIntervalType.daily:
      return isPlural ? 'DAYS' : 'DAY';
    case ChallengeGoalIntervalType.weekly:
      return isPlural ? 'WEEKS' : 'WEEK';
    default:
      return '';
  }
}

export function getNumberFormatForChallengeGoal(challengeGoal: ChallengeGoal): string {
  const integerFormat = '1.0-0';
  switch (challengeGoal) {
    case ChallengeGoal.kilometers:
      return '1.1-2';
    default:
      return integerFormat;
  }
}

export function hasEnded(challenge: Challenge): boolean {
  return new Date() > new Date(challenge.endDate);
}

export function isUpcoming(challenge: Challenge): boolean {
  return getMinutesLeftUntil(challenge.startDate) > 0;
}

export function hasStarted(challenge: Challenge): boolean {
  return new Date(challenge.startDate) < new Date();
}

export function toCompletedChallenge(challenge: Challenge, customerId: number): CompletedChallenge {
  return {
    id: challenge.id,
    badgeUrl: challenge.badge?.image,
    challengeName: challenge.name,
    top3Ranks: challenge.topRanking ?? [],
    ownRank: challenge.ranking?.find(metric => metric.customerId === customerId),
    earnedBadge: !!challenge.progress?.badge,
    hasGoal: !!challenge.goalValue,
    challengeGoal: challenge.goal
  };
}

export function getTranslatedGoalType(challengeGoalType: ChallengeGoalType): string | undefined {
  return `CHALLENGE.${challengeGoalType}`;
}

export function showRanking(challenge: Challenge): boolean {
  if (isUpcoming(challenge)) {
    return false;
  }
  if (challenge.challengeType === ChallengeType.partner) {
    return !!challenge.ranking?.length || !!challenge.topRanking?.length;
  } else if (challenge.challengeType === ChallengeType.partnerVsPartner) {
    return !!challenge.ranking?.length || !!challenge.partnerRanking?.length;
  }
  return !!(challenge.ranking?.length || challenge.topRanking?.length);
}
