import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule, formatDate } from '@angular/common';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DateRange, DateRangeState, RangeMode } from './model/date-select';
import { setToEndOfDay } from '@fitup-monorepo/core/lib/util/date.util';

type DateRangeUpdate = Pick<DateRangeState, 'startDate' | 'endDate' | 'renderedDate'>;

// @ts-ignore
Date.prototype.addDays = function (days): Date {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

@Component({
  selector: 'app-date-select',
  templateUrl: './date-select.component.html',
  styleUrls: ['./date-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonicModule, CommonModule],
  standalone: true
})
export class DateSelectComponent implements OnChanges {
  @Input()
  public defaultSelection: Date = new Date();

  @Input()
  public mode: RangeMode = RangeMode.month;

  @Input()
  public minDate: Date | undefined;

  @Input()
  public maxDate: Date = new Date();

  @Output()
  public dateChange = new EventEmitter<DateRange>();

  public readonly dateRangeState$: Subject<DateRangeState> = new BehaviorSubject<DateRangeState>(
    this.#setDateRangeState()
  );

  constructor(public readonly translateService: TranslateService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.#updateDateRangeState();
  }

  #updateDateRangeState(): void {
    this.dateRangeState$.next(this.#setDateRangeState());
  }

  #setDateRangeState(): DateRangeState {
    if (this.mode === RangeMode.daily || this.mode === RangeMode.week) {
      const currentDate = this.#setDefaultTime(this.defaultSelection);
      return this.buildNewState(currentDate, this.mode, 0);
    } else {
      const fromDate = new Date(this.defaultSelection);
      fromDate.setDate(1);
      fromDate.setHours(0, 0, 0, 0);
      return this.buildNewState(fromDate, this.mode, 0);
    }
  }

  public previousDateRange(state: DateRangeState): void {
    this.updateState(state, -1);
  }

  public nextDateRange(state: DateRangeState): void {
    this.updateState(state, 1);
  }

  private updateState(state: DateRangeState, difference: number): void {
    const newState = this.buildNewState(state.startDate, state.rangeMode, difference);
    this.dateRangeState$.next(newState);
  }

  #setDefaultTime(date: Date): Date {
    const dateCopy = new Date(date);
    dateCopy.setHours(0, 0, 0, 0);
    return dateCopy;
  }

  #isBeforeMaxDate(date: Date): boolean {
    return this.#setDefaultTime(date) < this.#setDefaultTime(this.maxDate);
  }

  #isAfterMinDate(date: Date): boolean {
    if (!this.minDate) {
      return true;
    }
    return this.#setDefaultTime(this.minDate) < this.#setDefaultTime(date);
  }

  public buildNewState(oldStartDate: Date, rangeMode: RangeMode, difference: number): DateRangeState {
    const { startDate, endDate, renderedDate } = this.#getNewDate(oldStartDate, rangeMode, difference);

    const state: DateRangeState = {
      rangeMode,
      previousAvailable: rangeMode === RangeMode.overall ? false : this.#isAfterMinDate(startDate),
      nextAvailable: rangeMode === RangeMode.overall ? false : this.#isBeforeMaxDate(endDate),
      renderedDate,
      startDate,
      endDate
    };
    this.dateChange.emit({ rangeMode, startDate, endDate });
    return state;
  }

  #getNewDate(oldStartDate: Date, rangeMode: RangeMode, difference: number): DateRangeUpdate {
    switch (rangeMode) {
      case RangeMode.daily:
        return this.#getDailyState(oldStartDate, difference);
      case RangeMode.week:
        return this.#getWeekState(oldStartDate, difference);
      case RangeMode.month:
        return this.#getMonthState(oldStartDate, difference);
      case RangeMode.quarter:
        return this.#getQuarterState(oldStartDate, difference);
      case RangeMode.year:
        return this.#getYearState(oldStartDate, difference);
      case RangeMode.overall:
        return this.#getOverallState();
      default:
        throw new Error('Unsupported RangeMode!');
    }
  }

  #getDailyState(oldStartDate: Date, difference: number): DateRangeUpdate {
    const startDate = new Date(oldStartDate);
    startDate.setDate(startDate.getDate() + difference);
    const endDate = setToEndOfDay(startDate);
    const isToday = startDate.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);
    const todayString = this.translateService.instant('DATE_SELECT.TODAY');
    const renderedDate = `${
      isToday ? todayString : formatDate(startDate, 'EEEE', this.translateService.currentLang)
    }, ${startDate.getDate()} ${startDate.toLocaleString(this.translateService.currentLang, {
      month: 'long'
    })}`;

    return { startDate, endDate, renderedDate };
  }

  #addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  #getWeekState(oldStartDate: Date, difference: number): DateRangeUpdate {
    const oldDate = new Date(oldStartDate);
    const currentDay = oldDate.getDay();
    const monday = 1;
    const differenceFromMonday = monday - currentDay;

    const lastMonday = this.#addDays(oldDate, differenceFromMonday);
    const nextSunday = this.#addDays(lastMonday, 6);

    const startDate = this.#addDays(lastMonday, 7 * difference);
    const endDate = this.#addDays(nextSunday, 7 * difference);

    const renderedDate = `${startDate.getDate()} ${startDate
      .toLocaleString(this.translateService.currentLang, {
        month: 'long'
      })
      .substring(0, 3)} - ${endDate.getDate()} ${endDate
      .toLocaleString(this.translateService.currentLang, {
        month: 'long'
      })
      .substring(0, 3)} ${endDate.getFullYear()}`;

    return { startDate, endDate: setToEndOfDay(endDate), renderedDate };
  }

  #getMonthState(oldStartDate: Date, difference: number): DateRangeUpdate {
    const startDate = new Date(oldStartDate);
    startDate.setMonth(oldStartDate.getMonth() + difference);
    const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    const renderedDate = `${startDate.toLocaleString(this.translateService.currentLang, {
      month: 'long'
    })} ${startDate.getFullYear()}`;

    return {
      startDate,
      endDate: setToEndOfDay(endDate),
      renderedDate
    };
  }

  #getQuarterState(oldStartDate: Date, difference: number): DateRangeUpdate {
    const quarter = Math.floor(oldStartDate.getMonth() / 3);
    const newQuarter = quarter + difference;
    const startMonth = (newQuarter % 4) * 3;
    const startYear = oldStartDate.getFullYear() + Math.floor(newQuarter / 4);

    const startDate = new Date(startYear, startMonth, 1);
    const endDate = new Date(startYear, startMonth + 3, 0);

    const renderedDate = `${startDate.toLocaleString(this.translateService.currentLang, {
      month: 'long'
    })} - ${endDate.toLocaleString(this.translateService.currentLang, { month: 'long' })} ${endDate.getFullYear()}`;

    return {
      startDate,
      endDate: setToEndOfDay(endDate),
      renderedDate
    };
  }

  #getYearState(oldStartDate: Date, difference: number): DateRangeUpdate {
    const startDate = new Date(oldStartDate);
    startDate.setMonth(0);
    startDate.setFullYear(oldStartDate.getFullYear() + difference);
    const endDate = new Date(startDate.getFullYear(), 11, 31);
    const renderedDate = `${startDate.getFullYear()}`;

    return { renderedDate, startDate, endDate: setToEndOfDay(endDate) };
  }

  #getOverallState(): DateRangeUpdate {
    return {
      startDate: this.minDate,
      endDate: this.maxDate,
      renderedDate: this.translateService.instant('DATE_SELECT.OVERALL')
    };
  }
}
