import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-reward-box',
  templateUrl: './reward-box.component.html',
  styleUrls: ['./reward-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonicModule],
  standalone: true
})
export class RewardBoxComponent {
  @Input({ required: true })
  public reward!: number;

  @HostBinding('class')
  @Input()
  public type: 'point' | 'coin' = 'point';
}
