import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { badgeActions } from '@fitup-monorepo/badges/lib/state/badge.actions';
import { dailyTasksActions } from '@fitup-monorepo/home-dashboard/lib/components/daily-tasks-overview/state/daily-tasks.actions';
import { challengeActions } from '@fitup-monorepo/challenge/lib/state/challenge.actions';
import { profileApiActions } from '@fitup-monorepo/core/lib/state/profile/profile.actions';
import { ActionError } from '@fitup-monorepo/core/lib/model/common-types';
import { ToastService } from '@fitup-monorepo/core/lib/services/toast/toast.service';
import { mediaCenterActions } from '@fitup-monorepo/media-center/lib/state/mediacenter.actions';
import { groupActions } from '@fitup-monorepo/challenge/lib/state/group/group.actions';
import { Action } from '@ngrx/store';

@Injectable()
export class ErrorEffects {
  public handleToastError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          profileApiActions.updateCustomerFailure,
          profileApiActions.setAvatarFailure,
          profileApiActions.deleteImageFailure,
          profileApiActions.uploadImageFailure,
          //profileApiActions.loadProfileFailure,
          mediaCenterActions.updateFavoriteFailure,
          mediaCenterActions.updateWatchProgressFailure,
          groupActions.createGroupFailure,
          groupActions.updateGroupFailure,
          groupActions.deleteGroupFailure
        ),
        tap(action => {
          this.logError(action);
          this.toastService.showError(action.errorMessage ?? 'GENERIC_ERROR');
        })
      ),
    { dispatch: false }
  );

  public handleLogging$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          mediaCenterActions.loadMediaEntryFailure,
          mediaCenterActions.loadOverviewFailure,
          mediaCenterActions.loadCategoryFailure,
          mediaCenterActions.loadFavoritesFailure,
          challengeActions.loadCommunityOverviewFailure,
          challengeActions.loadPartnerOverviewFailure,
          challengeActions.markCompletedChallengesShownFailure,
          badgeActions.loadEarnedBadgesFailure,
          dailyTasksActions.loadDailyTasksFailure
        ),
        tap(action => {
          this.logError(action);
        })
      ),
    { dispatch: false }
  );

  private logError(action: ActionError & Action<string>): void {
    console.error(`${action.type}: ${JSON.stringify(action.error)}`);
  }

  constructor(
    private readonly actions$: Actions,
    private readonly toastService: ToastService
  ) {}
}
