// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter {
  overflow-y: scroll;
}
.filter::-webkit-scrollbar {
  display: none;
}
.filter .box {
  white-space: nowrap;
  padding: var(--fu-spacing-ms);
  border-radius: var(--fu-radius-3xl);
  border: 1px solid var(--fu-grey-15);
  font-size: var(--fu-font-size-xxs);
  font-weight: var(--fu-font-medium);
}
.filter .box--checked {
  background-color: var(--fu-grey-15);
}`, "",{"version":3,"sources":["webpack://./libs/nutrition-planner/src/lib/nutrition-plan/recipes/recipe-filter/recipe-filter.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,aAAA;AACJ;AAEE;EACE,mBAAA;EACA,6BAAA;EACA,mCAAA;EACA,mCAAA;EACA,kCAAA;EACA,kCAAA;AAAJ;AAEI;EACE,mCAAA;AAAN","sourcesContent":[".filter {\n  overflow-y: scroll;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n\n  .box {\n    white-space: nowrap;\n    padding: var(--fu-spacing-ms);\n    border-radius: var(--fu-radius-3xl);\n    border: 1px solid var(--fu-grey-15);\n    font-size: var(--fu-font-size-xxs);\n    font-weight: var(--fu-font-medium);\n\n    &--checked {\n      background-color: var(--fu-grey-15);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
