import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChallengeGoal, MemberMetrics } from '../../model';
import { BannerComponent, BannerTheme } from '@fitup-monorepo/components/lib/banner/banner.component';
import { defaultProfileImage } from '@fitup-monorepo/core/lib/constants';
import { IonicModule, ModalController } from '@ionic/angular';
import { HeaderComponent } from '@fitup-monorepo/components/lib/header/header.component';
import { TranslatePipe } from '@ngx-translate/core';
import { selectCustomerUUID } from '@fitup-monorepo/core/lib/state/profile/profile.selector';
import { Store } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { ChallengeValuePipe } from '../../util/challenge-value.pipe';
import { ProfileNavigationDirective } from '@fitup-monorepo/components/lib/profile/profile-navigation.directive';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-partner-member-list',
  templateUrl: './partner-member-list.component.html',
  styleUrls: ['./partner-member-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    BannerComponent,
    IonicModule,
    HeaderComponent,
    TranslatePipe,
    CommonModule,
    ChallengeValuePipe,
    ProfileNavigationDirective
  ],
  standalone: true
})
export class PartnerMemberListComponent {
  @Input({ required: true })
  public partnerIcon!: string;

  @Input({ required: true })
  public partnerName!: string;

  @Input({ required: true })
  public challengeGoal!: ChallengeGoal;

  @Input({ required: true })
  public memberMetrics!: MemberMetrics[];

  protected readonly customerUuid$: Observable<string> = this.store.select(selectCustomerUUID);

  protected readonly bannerTheme = BannerTheme;
  protected readonly defaultProfileImage: string = defaultProfileImage;

  constructor(
    private readonly modalController: ModalController,
    private readonly store: Store
  ) {}

  protected async closeModal(): Promise<void> {
    await this.modalController.dismiss(null, null, 'partner-member-list-modal');
  }
}
