import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { selectCustomerUUID } from '@fitup-monorepo/core/lib/state/profile/profile.selector';
import { TeamMemberListModalComponent } from '../components/team-member-list-modal/team-member-list-modal.component';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { ModalService } from '@fitup-monorepo/components/lib/confirmation-modal/confirmation-modal.service';
import { SubscriptionPlanService } from '@fitup-monorepo/subscription/lib/subscription-plan/subscription-plan.service';
import { HttpStatusCode } from '@angular/common/http';
import { PartnerMemberListComponent } from '../components/partner-member-list/partner-member-list.component';
import { ChallengeGoal, MemberMetrics } from '../model';

@Injectable({ providedIn: 'root' })
export class ChallengeModalService {
  constructor(
    private readonly modalController: ModalController,
    private readonly ModalService: ModalService,
    private readonly subscriptionPlanService: SubscriptionPlanService,
    private readonly store: Store
  ) {}

  public async openTeamMemberListModal(isTeamRanking: boolean, teamId: number): Promise<void> {
    if (isTeamRanking) {
      const customerUuid = await firstValueFrom(this.store.select(selectCustomerUUID));
      await TeamMemberListModalComponent.openModal(this.modalController, teamId, customerUuid);
    }
  }

  public async openPartnerMemberListModal(
    partnerIcon: string,
    partnerName: string,
    challengeGoal: ChallengeGoal,
    memberMetrics: MemberMetrics[]
  ): Promise<void> {
    const modal = await this.modalController.create({
      id: 'partner-member-list-modal',
      component: PartnerMemberListComponent,
      componentProps: { partnerIcon, partnerName, challengeGoal, memberMetrics },
      cssClass: ['fu-modal-default']
    });

    await modal.present();
    await modal.onDidDismiss();
  }

  public async showModalForFreeUser(httpStatusCode: HttpStatusCode): Promise<void> {
    if (httpStatusCode === HttpStatusCode.Forbidden) {
      const confirm = await this.ModalService.showConfirmation({
        title: 'CHALLENGE.FREE_USER.TITLE',
        description: 'CHALLENGE.FREE_USER.DESCRIPTION',
        confirmButtonLabel: 'CHALLENGE.FREE_USER.CONFIRM',
        cancelButtonLabel: 'CHALLENGE.FREE_USER.CANCEL'
      });
      if (confirm) {
        await this.subscriptionPlanService.startSubscription();
      }
    }
  }
}
