export enum ChallengeGoal {
  steps = 'STEPS',
  minutes = 'MINUTES',
  meters = 'METERS',
  calories = 'CALORIES',
  kilometers = 'KILOMETERS',
  days = 'DAYS',
  hours = 'HOURS',
  fitScore = 'FIT_SCORE',
}

export enum ChallengeGoalType {
  strength = 'STRENGTH',
  mindfulness = 'MINDFULNESS',
  yoga = 'YOGA',
  overall = 'OVERALL',
  cardio = 'CARDIO',
  cycling = 'CYCLING',
  walking = 'WALKING',
  running = 'RUNNING',
}
