import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { Store } from '@ngrx/store';
import { profileApiActions } from '../../state/profile/profile.actions';
import { LocalStorageService } from '../local-storage.service';

export interface AuthCredentials {
  username: string;
  password: string;
  rememberMe: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthServerProvider {
  private tokenSubject = new BehaviorSubject<string | null>(null);

  constructor(
    private readonly http: HttpClient,
    private readonly localStorage: LocalStorageService,
    private readonly store: Store
  ) {
    const token = this.localStorage.retrieve('authenticationToken') as string;
    if (token && token !== 'undefined' && token !== 'null' && token.length > 0) {
      console.log('Token retrieved from local storage');
      this.tokenSubject.next(token);
    }
  }

  public observeToken(): BehaviorSubject<string | null> {
    return this.tokenSubject;
  }

  public isAuthenticated(): boolean {
    return this.tokenSubject.value !== null;
  }

  public async login(credentials: AuthCredentials): Promise<void> {
    const data = {
      username: credentials.username,
      password: credentials.password,
      rememberMe: true
    };

    const resp = await firstValueFrom(
      this.http.post(`${ApiService.API_URL}/authenticate`, data, { observe: 'response' })
    );
    const bearerToken = resp.headers.get('Authorization');
    if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
      const jwt = bearerToken.slice(7, bearerToken.length);
      this.storeAuthenticationToken(jwt);
      this.store.dispatch(profileApiActions.loadProfile());
    } else {
      throw new Error('No bearer token found in response');
    }
  }

  public getUpdatedToken(): Observable<string> {
    return (
      this.http
        // eslint-disable-next-line @typescript-eslint/naming-convention
        .get<{ id_token: string }>(`${ApiService.API_URL}/authenticate`)
        .pipe(map(response => response.id_token))
    );
  }

  public async updateJWT(): Promise<void> {
    if (!!this.tokenSubject.value) {
      console.log('updating JWT token');
      const newToken = await firstValueFrom(this.getUpdatedToken());
      this.storeAuthenticationToken(newToken);
      this.jwtUpdatedAt = new Date();
      console.log('JWT token updated');
    } else {
      console.log('no JWT token to update');
      throw new Error('no JWT token to update');
    }
  }

  private jwtUpdatedAt: Date = new Date(0);

  private readonly maxTokenAgeMillis = 6 * 60 * 60 * 1000;

  /**
   * Updates the JWT token if it is older than 24 hours
   * @returns true if the token was updated or still valid, false if the token is invalid
   */
  public async updateJWTIfNecessary(): Promise<void> {
    const ageMillis = Date.now() - this.jwtUpdatedAt.getTime();
    console.log('Checking if JWT token needs to be updated. Last update at, ageMillis', this.jwtUpdatedAt, ageMillis);
    if (ageMillis > this.maxTokenAgeMillis) {
      try {
        await this.updateJWT();
      } catch (e) {
        console.error('Error updating JWT token', e);
      }
    }
  }

  public async loginWithToken(jwt: string): Promise<void> {
    if (jwt) {
      this.storeAuthenticationToken(jwt);
      this.store.dispatch(profileApiActions.loadProfile());
    } else {
      return Promise.reject('auth-jwt-service Promise reject'); // Put appropriate error message here
    }
  }

  private storeAuthenticationToken(jwt: string): void {
    this.localStorage.store('authenticationToken', jwt);
    if (!!jwt) {
      this.tokenSubject.next(jwt);
    }
  }

  private async doLogout(deviceId: String, firebaseToken: String): Promise<void> {
    try {
      await firstValueFrom(
        this.http.post<void>(`${ApiService.API_URL}/logout`, {
          deviceId,
          token: firebaseToken
        })
      );
    } catch (e) {
      console.error('Error logging out', e);
    }
    this.localStorage.clear('authenticationToken');
    this.tokenSubject.next(null);
  }

  public logout(deviceId: string, firebaseToken: string): Observable<void> {
    return fromPromise(this.doLogout(deviceId, firebaseToken));
  }
}
