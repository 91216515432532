import { Inject, Injectable } from '@angular/core';
import { Bugfender } from '@bugfender/capacitor';
import { CustomerSelectService } from '../customer-select/customer-select.service';
import { environment } from '../../environment';
import { BugfenderFacade } from '@bugfender/common';
import { BUGFENDER } from '../../capacitor-injection-tokens';
import { LocalStorageService } from '../local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  private storageKey = 'forceSendOnce';

  constructor(
    private customerSelectService: CustomerSelectService,
    private storageService: LocalStorageService,
    @Inject(BUGFENDER) private readonly bugfender: BugfenderFacade
  ) {}

  public async init(version: string): Promise<void> {
    if (!!environment.bugfender?.appKey) {
      await Bugfender.init({
        appKey: environment.bugfender.appKey,
        overrideConsoleMethods: true,
        printToConsole: true,
        registerErrorHandler: false,
        logBrowserEvents: true,
        logUIEvents: false,
        version: version
      });
      console.log(`Bugfender initialized with version ${version}`);
      if (!this.storageService.retrieve(this.storageKey)) {
        this.bugfender.forceSendOnce();
        this.storageService.store(this.storageKey, true);
      }

      this.customerSelectService.selectCustomer().then(customer => {
        this.bugfender.setDeviceKey('customerId', `${customer.id}`);
        this.bugfender.setDeviceKey('email', `${customer.email}`);
      });
    }
  }
}
