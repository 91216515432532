// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .reward-box {
  padding: var(--fu-spacing-s) var(--fu-spacing-m);
  border-radius: var(--fu-radius-input);
  width: 100%;
  max-width: 300px;
  min-width: 170px;
}
:host.point .reward-box {
  border: 2px solid var(--fu-blue);
  background: rgba(var(--fu-blue-rgb), 0.05);
}
:host.coin .reward-box {
  background: rgba(var(--fu-orange-light-rgb), 0.05);
  border: 2px solid var(--fu-orange-light);
}`, "",{"version":3,"sources":["webpack://./libs/components/src/lib/reward-box/reward-box.component.scss"],"names":[],"mappings":"AAEE;EACE,gDAAA;EACA,qCAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;AADJ;AAKI;EACE,gCAAA;EACA,0CAAA;AAHN;AAQI;EACE,kDAAA;EACA,wCAAA;AANN","sourcesContent":[":host {\n\n  .reward-box {\n    padding: var(--fu-spacing-s) var(--fu-spacing-m);\n    border-radius: var(--fu-radius-input);\n    width: 100%;\n    max-width: 300px;\n    min-width: 170px;\n  }\n\n  &.point {\n    .reward-box {\n      border: 2px solid var(--fu-blue);\n      background: rgba(var(--fu-blue-rgb), 0.05);\n    }\n  }\n\n  &.coin {\n    .reward-box {\n      background: rgba(var(--fu-orange-light-rgb), 0.05);\n      border: 2px solid var(--fu-orange-light);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
