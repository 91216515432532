import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { ToastService } from '../services/toast/toast.service';
import { PromoCodeService } from '../services/promo-code/promo-code.service';
import { PlatformService } from '../services/platform/platform.service';
import { BrowserService } from '../services/browser/browser.service';
import { executeWithToastErrorHandling, isErrorResult } from '../util/rxjs-util';
import { LocalStorageService } from '../services/local-storage.service';

export const codeInvitationKey = 'promoCodeInvitation';

interface ApplyCodeConfig {
  errorRoute: string;
  paramName: string;
}

async function applyCode(route: ActivatedRouteSnapshot, config: ApplyCodeConfig): Promise<UrlTree> {
  const router = inject(Router);
  const toastService = inject(ToastService);
  const promoCodeService = inject(PromoCodeService);
  const localStorageService = inject(LocalStorageService);
  const platformService = inject(PlatformService);
  const browserService = inject(BrowserService);

  const code = route.paramMap.get(config.paramName);
  const storedPromotionCode = localStorageService.retrieve(codeInvitationKey);
  const nextUrlTree = router.createUrlTree(['']);

  const isValid = await executeWithToastErrorHandling({
    observable$: promoCodeService.isValidPromotionCode(code),
    toastService
  });
  if (isErrorResult(isValid)) {
    return nextUrlTree;
  }
  if (!isValid) {
    return router.createUrlTree([config.errorRoute]);
  }
  if (!platformService.isNative() && !storedPromotionCode) {
    await browserService.openUrlInApp(router.url, () => {
      localStorageService.store(codeInvitationKey, code);
    });
  } else {
    localStorageService.store(codeInvitationKey, code);
  }
  return nextUrlTree;
}

export const enterVoucherGuard: CanActivateFn = async (route: ActivatedRouteSnapshot) => {
  return applyCode(route, {
    errorRoute: '/voucher-error',
    paramName: 'voucher'
  });
};

export const enterPromotionCodeGuard: CanActivateFn = async (route: ActivatedRouteSnapshot) => {
  return applyCode(route, {
    errorRoute: '/promo-code-error',
    paramName: 'promotionCode'
  });
};
