import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ActionError } from '@fitup-monorepo/core/lib/model/common-types';
import { CoinOverview } from '../model/coins';

export const rewardSystemActions = createActionGroup({
  source: 'Reward System',
  events: {
    ['Load Coin Overview']: emptyProps(),
    ['Load Coin Overview Success']: props<{ overview: CoinOverview }>(),
    ['Load Coin Overview Failure']: props<ActionError>()
  }
});
