export interface DateRangeState {
  rangeMode: RangeMode;
  previousAvailable: boolean;
  nextAvailable: boolean;
  renderedDate: string;
  startDate: Date;
  endDate: Date;
}

export type DateRange = Pick<DateRangeState, 'startDate' | 'endDate' | 'rangeMode'>;

export enum RangeMode {
  daily = 'daily',
  week = 'week',
  month = 'month',
  year = 'year',
  overall = 'overall',
  quarter = 'quarter'
}
