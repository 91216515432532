import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { AuthServerProvider } from '../services/auth/auth-jwt.service';
import { PlatformService } from '../services/platform/platform.service';
import { BrowserService } from '../services/browser/browser.service';
import { LocalStorageService } from '../services/local-storage.service';

export const groupIdToJoinKey = 'groupIdToJoinKey';

export const joinGroupGuard: CanActivateFn = async (route: ActivatedRouteSnapshot): Promise<UrlTree> => {
  const localStorageService = inject(LocalStorageService);
  const authServerProvider = inject(AuthServerProvider);
  const router = inject(Router);
  const platformService = inject(PlatformService);
  const browserService = inject(BrowserService);
  const groupId = route.paramMap.get('groupId');

  const storedGroupId = localStorageService.retrieve(groupIdToJoinKey);
  if (!platformService.isNative() && !storedGroupId) {
    await browserService.openUrlInApp(`join-group/${groupId}`, () => {
      localStorageService.store(groupIdToJoinKey, +groupId);
    });
  } else {
    localStorageService.store(groupIdToJoinKey, +groupId);
  }

  if (!authServerProvider.isAuthenticated()) {
    return router.createUrlTree(['']);
  }

  return router.createUrlTree(['groups', groupId]);
};
