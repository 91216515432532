import { MemberMetrics } from './member-metrics';
import { Challenge } from './challenge';
import { TeamMetrics } from './team-metrics';

export interface PartnerChallenges {
  ownTeam?: TeamMetrics;
  challenges: Challenge[];
  teamRanking: TeamMetrics[];
  memberRanking: MemberMetrics[];
}
