import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { dailyTasksActions } from './daily-tasks.actions';
import { DailyTasksService } from '../daily-tasks.service';
import { currentDate } from '@fitup-monorepo/core/lib/util/rxjs-util';

@Injectable()
export class DailyTasksEffects {
  public readonly loadOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dailyTasksActions.loadDailyTasks),
      switchMap(() => currentDate()),
      switchMap(date =>
        this.dailyTasksService.getOverview(date).pipe(
          map(overview => dailyTasksActions.loadDailyTasksSuccess(overview)),
          catchError(error => of(dailyTasksActions.loadDailyTasksFailure({ error })))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly dailyTasksService: DailyTasksService
  ) {}
}
