import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  DestroyRef,
  inject,
  Input
} from '@angular/core';
import { FormControl, FormControlDirective, FormControlName, ReactiveFormsModule } from '@angular/forms';
import { IonicModule, IonInput } from '@ionic/angular';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export const INPUT_ERRORS: { [key: string]: string } = {
  required: 'INPUT_VALIDATION.ERROR.REQUIRED',
  email: 'INPUT_VALIDATION.ERROR.EMAIL',
  minlength: 'INPUT_VALIDATION.ERROR.MINLENGTH',
  maxlength: 'INPUT_VALIDATION.ERROR.MAXLENGTH'
};

@Component({
  selector: 'app-input-element',
  templateUrl: './input-element.component.html',
  styleUrls: ['./input-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonicModule, ReactiveFormsModule, TranslateModule],
  standalone: true
})
export class InputElementComponent implements AfterViewInit {
  @Input()
  public iconSrc: string | undefined;

  @Input()
  public inputStyle: 'line' | 'default' = 'default';

  @Input()
  public inputSize: 'small' | 'default' | 'medium' = 'default';

  @Input()
  public inputHeight: number;

  @ContentChild(FormControlName)
  public formControlName: FormControlName | undefined;

  @ContentChild(FormControlDirective)
  public formControl: FormControlDirective | undefined;

  @ContentChild(IonInput)
  public input: IonInput | undefined;

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly translateService: TranslateService
  ) {}

  public ngAfterViewInit(): void {
    this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });
    // We need this unfortunately as there is a bug in ion-inputs:
    // https://github.com/ionic-team/ionic-v3/issues/928
    this.input?.getInputElement().then(input => {
      input.addEventListener('change', event => {
        this.control.setValue((event.target as any).value);
      });
    });
  }

  public get control(): FormControl {
    if (!this.formControlName && !this.formControl) {
      throw new Error('Form control element needs to be provided!');
    }
    return this.formControlName?.control || this.formControl.control;
  }

  public showError(): boolean {
    return this.control.dirty && this.control.invalid;
  }

  public getError(): string {
    const errors = Object.keys(this.control.errors);
    const firstError: string | undefined = errors.length ? errors[0] : undefined;
    const translateKey = firstError ? INPUT_ERRORS[firstError] : '';
    return this.translateService.instant(translateKey).replace('$VALUE', this.#getRequiredValue(firstError));
  }

  #getRequiredValue(errorKey: string): unknown {
    const error = this.control.errors[errorKey];
    if (!error) {
      return undefined;
    }
    switch (errorKey) {
      case 'maxlength':
      case 'minlength':
        return error.requiredLength;
      default:
        return undefined;
    }
  }
}
