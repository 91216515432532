import { Observable } from 'rxjs';
import { Permission, PermissionsService } from '../permissions/permissions.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

export enum Feature {
  challenge = 'challenge',
  teamChallenge = 'team-challenge',
  publicChallenge = 'public-challenge',
  challengeGroup = 'challenge-group',
  pinboard = 'pinboard',
  waterIntake = 'water-intake',
  miniChallenge = 'mini-challenge',
  fitAtWorkReminder = 'fit-at-work-reminder',
  mediaCenter = 'media-center',
  live = 'live',
  activityTracking = 'activity-tracking',
  virtualCoach = 'virtual-coach',
  nutrition = 'nutrition',
  connectivity = 'connectivity', //any connection to a 3rd party service. if either healthApi or fitnessApi is enabled, this should be enabled as well
  healthApi = 'health-api', //connection to Apple Health or Google Fit/Health Connect
  fitnessApi = 'fitness-api', //connection to Garmin, Fitbit, Suunto, Polar, etc...
  badges = 'badges',
  activityStats = 'activity-stats',
  fitPoints = 'fit-points',
  dailyTasks = 'daily-tasks',
  homeStats = 'home-stats',
  startGuide = 'start-guide',
  rewards = 'rewards'
}

export enum OptionalHomePageElement {
  reminderStats = 'reminder-stats'
}

export interface FeatureTab {
  activeIcon: string;
  inactiveIcon: string;
  name: string;
  tabTextKey: string;
  route: string;
  feature?: Feature[];
  showPoint: boolean; //TODO: where does this come from?
}

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  constructor(private readonly permissionService: PermissionsService) {}

  private allTabs: FeatureTab[] = [
    {
      name: 'home',
      tabTextKey: 'TAB_HOME',
      route: '/tabs/home',
      activeIcon: 'home-solid',
      inactiveIcon: 'home-line',
      showPoint: false
    },
    {
      name: 'video',
      tabTextKey: 'TAB_MEDIA_CENTER',
      route: '/tabs/video',
      activeIcon: 'media-solid',
      inactiveIcon: 'media-line',
      feature: [Feature.mediaCenter, Feature.fitAtWorkReminder],
      showPoint: false
    },
    {
      name: 'activity',
      tabTextKey: 'TAB_ACTIVITY',
      route: '/tabs/activity',
      activeIcon: 'route-solid',
      inactiveIcon: 'route-line',
      feature: [Feature.activityTracking],
      showPoint: false
    },
    {
      name: 'virtual-coach',
      tabTextKey: 'TAB_VIRTUAL_COACH',
      route: '/tabs/virtual-coach',
      activeIcon: 'clipboard-check-solid',
      inactiveIcon: 'clipboard-check-line',
      feature: [Feature.virtualCoach],
      showPoint: false
    },
    {
      name: 'nutrition-planner',
      tabTextKey: 'TAB_NUTRITION_PLANNER',
      route: '/tabs/nutrition-planner',
      activeIcon: 'bowl-rice-solid',
      inactiveIcon: 'bowl-rice-line',
      feature: [Feature.nutrition],
      showPoint: false
    }
  ];

  private extraTabs: FeatureTab[] = [
    {
      name: 'reminder',
      tabTextKey: 'TAB_REMINDER',
      route: '/tabs/reminder',
      activeIcon: 'bell-ringing-solid',
      inactiveIcon: 'bell-ringing-line',
      feature: [Feature.fitAtWorkReminder],
      showPoint: false
    }
  ];

  private featureVisibleForCorporateUser(permissions: Set<Permission>, feature: Feature): boolean {
    switch (feature) {
      case Feature.challenge:
        return (
          permissions.has(Permission.teamChallenge) ||
          permissions.has(Permission.publicChallenge) ||
          permissions.has(Permission.free)
        );
      case Feature.teamChallenge:
        return permissions.has(Permission.teamChallenge);
      case Feature.publicChallenge:
        return (
          permissions.has(Permission.publicChallenge) ||
          permissions.has(Permission.free) ||
          permissions.has(Permission.teamChallenge)
        );
      case Feature.challengeGroup:
        return permissions.has(Permission.publicChallenge) || permissions.has(Permission.free);
      case Feature.pinboard:
        return permissions.has(Permission.pinboard);
      case Feature.waterIntake:
        return permissions.has(Permission.waterIntake) || permissions.has(Permission.free);
      case Feature.miniChallenge:
        return permissions.has(Permission.miniChallenge) || permissions.has(Permission.free);
      case Feature.fitAtWorkReminder:
        return permissions.has(Permission.fitAtWorkReminder);
      case Feature.mediaCenter:
        return permissions.has(Permission.mediaCenter) || permissions.has(Permission.free);
      case Feature.live:
        return permissions.has(Permission.live);
      case Feature.activityTracking:
        return permissions.has(Permission.activityTracking) || permissions.has(Permission.free);
      case Feature.virtualCoach:
        return permissions.has(Permission.virtualCoach) || permissions.has(Permission.free);
      case Feature.nutrition:
        return (
          permissions.has(Permission.nutrition) || permissions.has(Permission.free) || permissions.has(Permission.zpp)
        );
      case Feature.rewards:
        return permissions.has(Permission.rewards);
      case Feature.connectivity: //intentional fallthrough
      case Feature.healthApi:
      case Feature.fitnessApi:
      case Feature.badges:
      case Feature.activityStats:
      case Feature.fitPoints:
      case Feature.dailyTasks:
      case Feature.homeStats:
      case Feature.startGuide:
        return permissions.has(Permission.basic) || permissions.has(Permission.free);
      default:
        return true;
    }
  }

  public isHomePageElementVisible(element: OptionalHomePageElement): boolean {
    if (this.permissionService.isCorporateUser()) {
      const permissions = this.permissionService.getAllPermissions();
      return (
        this.featureVisibleForCorporateUser(permissions, Feature.fitAtWorkReminder) &&
        !this.featureVisibleForCorporateUser(permissions, Feature.homeStats) &&
        !this.featureVisibleForCorporateUser(permissions, Feature.dailyTasks)
      );
    } else {
      return false;
    }
  }

  public isVisible(feature: Feature): boolean {
    if (!feature) return true;

    if (this.permissionService.isCorporateUser()) {
      const permissions = this.permissionService.getAllPermissions();
      return this.featureVisibleForCorporateUser(permissions, feature);
    } else {
      switch (feature) {
        case Feature.live:
          return this.permissionService.hasPermission(Permission.live);
        case Feature.rewards:
          return this.permissionService.hasPermission(Permission.rewards);
        default:
          return true;
      }
    }
  }

  private hasAnyFeature(permissions: Set<Permission>, features: Feature[]): boolean {
    return features.some(feature => this.featureVisibleForCorporateUser(permissions, feature));
  }

  private filterTabs(permissions: Set<Permission>, tabs: FeatureTab[]): FeatureTab[] {
    return tabs.filter(tab => (tab.feature ? this.hasAnyFeature(permissions, tab.feature) : true));
  }

  public getFeatureTabs(): Observable<FeatureTab[]> {
    return this.permissionService.observerPermissions().pipe(
      map(permissions => {
        const availableTabs = this.filterTabs(permissions, this.allTabs);

        if (availableTabs.length < 3) {
          availableTabs.push(...this.filterTabs(permissions, this.extraTabs));
        }

        return availableTabs;
      })
    );
  }

  public tabClicked(name: string): void {}
}
