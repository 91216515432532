export interface MemberMetrics {
  rank: number;
  teamColor?: string;
  profileImageUrl?: string;
  customerId?: number;
  customerUuid?: string;
  nickName: string;
  value: number;
  partnerColor?: string;
  partnerIconUrl?: string;
  partnerId: number;
}
