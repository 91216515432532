import { formatDate } from '@angular/common';

export function formatDateForPicker(date: Date | string, locale: string): string {
  return formatDate(date, 'yyyy-MM-ddTHH:mm:ss', locale, Intl.DateTimeFormat().resolvedOptions().timeZone);
}

export function formatDateToTimeString(date: Date | string, locale: string, format = 'HH:mm:ss'): string {
  return formatDate(date, format, locale, Intl.DateTimeFormat().resolvedOptions().timeZone);
}

/**
 * Converts a date to a string in the format 'yyyy-MM-dd' in the local timezone.
 * @param date date object
 * @returns string in the format 'yyyy-MM-dd'
 */
export function toLocalDateString(date: Date): string {
  return date.toLocaleDateString('en-CA'); //the Canadian date format is yyyy-MM-dd
}

export function timeStringToDate(timeString: string): Date | null {
  const timeParts = timeString.split(':');

  if (timeParts.length === 3) {
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const seconds = parseInt(timeParts[2], 10);

    if (!isNaN(hours) && !isNaN(minutes) && !isNaN(seconds)) {
      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);
      date.setSeconds(seconds);
      date.setMilliseconds(0);
      return date;
    }
  }
  throw new Error('Invalid format');
}

export function setToEndOfDay(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
}

export function isSameDate(date1: Date | string, date2: Date | string): boolean {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
}

export function isAfterToday(date: Date | string): boolean {
  return new Date(date) > new Date();
}

export function isBeforeToday(date: Date | string): boolean {
  return new Date(date) < new Date();
}

export function isToday(date: Date): boolean {
  return isSameDate(date, new Date());
}

export function addDays(date: Date, days: number): Date {
  const newDate = new Date(date.valueOf());
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

export function addMonths(date: Date, months: number): Date {
  const newDate = new Date(date.valueOf());
  newDate.setDate(newDate.getDate() + months);
  return newDate;
}

export function addHoursToDate(date: Date, hoursToAdd: number): Date {
  const result = new Date(date);
  result.setHours(date.getHours() + hoursToAdd);
  return result;
}

export function addSecondsToDate(date: Date, secondsToAdd: number): Date {
  const result = new Date(date);
  result.setSeconds(date.getSeconds() + secondsToAdd);
  return result;
}

export function setDefaultTime(date: Date): Date {
  const dateCopy = new Date(date);
  dateCopy.setHours(0, 0, 0, 0);
  return dateCopy;
}

export function setTime(date: Date, hours: number, min: number, sec?: number, ms?: number): Date {
  const dateCopy = new Date(date);
  dateCopy.setHours(hours, min, sec ?? 0, ms ?? 0);
  return dateCopy;
}

export function getFirstDateOfNextMonth(): Date {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + 1);
  currentDate.setDate(1);
  return currentDate;
}

export function getLastDateOfNextMonth(): Date {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + 2);
  currentDate.setDate(0);
  return currentDate;
}

function getTimeDifferenceInMilliseconds(startDate: Date, endDate: Date): number {
  return endDate.getTime() - startDate.getTime();
}

export function getDaysBetweenDates(startDate: Date, endDate: Date): number {
  const timeDifference = getTimeDifferenceInMilliseconds(startDate, endDate);
  return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
}

export function getWeeksBetweenDates(startDate: Date, endDate: Date): number {
  const timeDifference = getDaysBetweenDates(startDate, endDate);
  return Math.ceil(timeDifference / 7);
}

export function toCurrentTimezone(utcDate: Date): Date {
  const date = new Date(utcDate);
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return date;
}

export function secondsBetween(date1: Date, date2: Date): number {
  const timestamp1 = date1.getTime();
  const timestamp2 = date2.getTime();
  return Math.floor((timestamp2 - timestamp1) / 1000);
}

export function daysBetween(date1: Date, date2: Date): number {
  const diffInSeconds = secondsBetween(date1, date2);
  return Math.floor(diffInSeconds / (60 * 60 * 24)); // divide by the number of seconds in a day
}

export function minutesBetween(date1: Date, date2: Date): number {
  const diffInSeconds = secondsBetween(date1, date2);
  return Math.floor(diffInSeconds / 60);
}

export function hoursBetween(date1: Date, date2: Date): number {
  const diffInMinutes = minutesBetween(date1, date2);
  return Math.floor(diffInMinutes / 60);
}

export function getDaysLeftUntil(date: Date | string): number {
  return daysBetween(new Date(), new Date(date));
}

export function getRoundedDaysLeftUntil(date: Date | string): number {
  return Math.round(getHoursLeftUntil(date) / 24);
}

export function getHoursLeftUntil(date: Date | string): number {
  return hoursBetween(new Date(), new Date(date));
}

export function getMinutesLeftUntil(date: Date | string): number {
  return minutesBetween(new Date(), new Date(date));
}

export function getCurrentMonthStartDate(): Date {
  return new Date(getCurrentYear(), getCurrentMonth(), 1);
}

export function getCurrentYear(): number {
  return new Date().getFullYear();
}

export function getCurrentMonth(): number {
  return new Date().getMonth();
}

export function getCurrentMonthEndDate(): Date {
  const nextMonth = getCurrentMonth() + 1;
  return setToEndOfDay(new Date(getCurrentYear(), nextMonth, 0));
}

export function isWithinRange(date: Date | string, from: Date | string, to: Date | string): boolean {
  return new Date(date) >= new Date(from) && new Date(date) <= new Date(to);
}

export function calculateAge(date: string): number {
  const currentDate = new Date();
  const birthdate = new Date(date);
  let age = currentDate.getFullYear() - birthdate.getFullYear();
  const month = currentDate.getMonth() - birthdate.getMonth();
  if (month < 0 || (month === 0 && currentDate.getDate() < birthdate.getDate())) {
    age--;
  }

  return age;
}

export function convertDateFormat(date: string): string {
  return formatDate(date, 'YYYY-MM-dd', 'en-US');
}
