import { MemberMetrics } from './member-metrics';
import { ChallengeGoal, ChallengeGoalType } from './challenge-goal';
import { TeamMetrics } from './team-metrics';
import { ChallengeInterval, ChallengeGoalIntervalType } from './interval';
import { PartnerMetric } from './partner-metric';
import { TranslatableString } from '@fitup-monorepo/core/lib/services/translatable-text/translatable-string';
import { Badge, EarnedBadge } from '@fitup-monorepo/core/lib/model/badge';

export interface Challenge {
  challengeType: ChallengeType;
  id: number;
  name: TranslatableString;
  description: TranslatableString;
  details: TranslatableString;
  isRankingChallenge: boolean;
  startDate: string;
  endDate: string;
  active: boolean;
  imageUrl?: string;
  badge?: Badge;
  goal: ChallengeGoal;
  goalValue?: number;
  challengeGoalType: ChallengeGoalType;
  progress?: ChallengeProgress;
  ranking?: MemberMetrics[];
  teamRanking?: TeamMetrics[];
  joinedUsers?: JoinedUsers;
  topRanking?: MemberMetrics[];
  intervals?: ChallengeInterval[];
  minIntervalGoalCompletions?: number;
  goalInterval?: ChallengeGoalIntervalType;
  mode: ChallengeGoalMode;
  partnerRanking?: PartnerMetric[];
  hasJoined: boolean;
  canJoin: boolean;
  canLeave: boolean;
}

export enum ChallengeType {
  partner = 'partner',
  community = 'community',
  partnerVsPartner = 'partnerVsPartner'
}

export interface ChallengeProgress {
  value: number;
  totalScore: number;
  completed: boolean;
  completedShown: boolean;
  badge?: EarnedBadge;
  intervalScores?: number[];
}

export interface JoinedUsers {
  count: number;
  profileImages: string[];
}

export enum ChallengeGoalMode {
  averageTeam = 'averageTeam',
  sumTeam = 'sumTeam',
  sumCompany = 'sumCompany',
  member = 'member'
}
