import { createReducer, on } from '@ngrx/store';
import { rewardSystemActions } from './reward-system.actions';
import { CoinOverview } from '../model/coins';

export interface RewardSystemState {
  overview: CoinOverview;
  loading: boolean;
}

const initialState: RewardSystemState = {
  overview: undefined,
  loading: false
};

export const rewardSystemReducer = createReducer(
  initialState,
  on(rewardSystemActions.loadCoinOverview, (state): RewardSystemState => ({ ...state, loading: true })),
  on(
    rewardSystemActions.loadCoinOverviewSuccess,
    (state, { overview }): RewardSystemState => ({ ...state, overview, loading: false })
  ),
  on(rewardSystemActions.loadCoinOverviewFailure, (state): RewardSystemState => ({ ...state, loading: false }))
);
