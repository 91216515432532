import {TranslatableString} from "@fitup-monorepo/core/lib/services/translatable-text/translatable-string";

export enum ChallengeGoalIntervalType {
  daily = 'DAILY',
  weekly = 'WEEKLY',
  overall = 'OVERALL'
}

export interface ChallengeInterval {
  name: TranslatableString;
  image?: string;
  from: string;
  to: string;
}
